import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { CheckGuardianDataRouter } from './check-guardian-data-router'
import { OverviewPage } from './overview-page'
import { NewContractRouter } from './new-contract-router'
import { useEffect } from 'react'
import { useGuardianContext } from '../guardian-context'
import { useInstitutionContext } from '../../institution-context'

const NextSchoolYearRouter = () => {
  const fetchTaxInformation = useGuardianContext((ctx) => ctx.fetchTaxInformation)
  const { pathname } = useLocation()
  useEffect(() => {
    fetchTaxInformation()
  }, [fetchTaxInformation])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const navigate = useNavigate()
  const { institution } = useInstitutionContext()

  return (
    <>
      <Outlet />
      <Routes>
        <Route path="*" element={<OverviewPage />} />
        <Route path="check-guardian-data/*" element={<CheckGuardianDataRouter />} />
        <Route
          path="add-child-contract/:childId/*"
          element={
            <NewContractRouter
              startDate={institution?.registrationConfig.schoolYearStartDate}
              endDate={institution?.registrationConfig.schoolYearEndDate}
              onFinish={() => {
                navigate('../')
              }}
            />
          }
        />
      </Routes>
    </>
  )
}

export { NextSchoolYearRouter }
