import { nanoid } from 'nanoid'
import { useState } from 'react'
import { Outlet, Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { NewChildBaseInformation } from '../../../../components/child/child-form/child-base-information-form'
import { NewChildFurtherInformation } from '../../../../components/child/child-form/child-further-information-form'
import { NewChildImportantInformation } from '../../../../components/child/child-form/child-important-information-form'
import { useInstitutionContext } from '../../institution-context'
import BaseInformationPage from '../../registration/form-pages/child-form-pages/base-information-page'
import FurtherInformationPage from '../../registration/form-pages/child-form-pages/further-information-page'
import ImportantInformationPage from '../../registration/form-pages/child-form-pages/important-information-page'

import { useGuardianContext } from '../guardian-context'
import ModuleFormPage from '../../registration/form-pages/child-form-pages/module-form-page'
import ConfirmationFormPage from '../../registration/form-pages/confirmation-form-page'
import { ModuleBooking } from '../../registration/registration-context'
import { Contract, RegistrationConfiguration, SignatureData } from '../../../../types'
import { addNewContract } from '../../../../api/new-contract'
import dayjs from 'dayjs'
import { GuardianErrorElement } from '../guardian-error-element'

const NewChildRouter = () => {
  const { family, guardian } = useGuardianContext()
  const { institution } = useInstitutionContext()

  const navigate = useNavigate()

  const [newChildBaseInformation, setNewChildBaseInformation] = useState<NewChildBaseInformation | undefined>(undefined)
  const [newChildImportantInformation, setNewChildImportantInformation] = useState<
    NewChildImportantInformation | undefined
  >(undefined)
  const [newChildFurtherInformation, setNewChildFurtherInformation] = useState<NewChildFurtherInformation | undefined>(
    undefined
  )
  const [moduleBooking, setModuleBooking] = useState<ModuleBooking | undefined>()
  const [signatureData, setSignatureDate] = useState<SignatureData | undefined>()

  const [newChildId] = useState<string>(nanoid())

  const baseInformationSubmitHandler = (data: NewChildBaseInformation) => {
    setNewChildBaseInformation(data)
    navigate('important-information')
  }

  const baseInformationPreviousHandler = () => {
    navigate('../family')
  }

  const importantInformationSubmitHandler = (data: NewChildImportantInformation) => {
    setNewChildImportantInformation(data)
    navigate('further-information')
  }

  const importantInformationPreviousHandler = () => {
    navigate('base-information')
  }

  const furtherInformationSubmitHandler = async (data: NewChildFurtherInformation) => {
    setNewChildFurtherInformation(data)
    navigate('further-information')

    const body = JSON.stringify({
      familyId: family?.id,
      child: {
        id: newChildId,
        familyId: family?.id,
        firstName: newChildBaseInformation?.firstName,
        lastName: newChildBaseInformation?.lastName,
        dateOfBirth: newChildBaseInformation?.dateOfBirth,
        nationality: newChildBaseInformation?.nationality,
        sex: newChildBaseInformation?.sex,
        familyLanguage: newChildBaseInformation?.familyLanguage,
        class: newChildBaseInformation?.class,
        kinderGarten: newChildBaseInformation?.kinderGarten,
        schoolId: newChildBaseInformation?.schoolId,
        pickUp: newChildBaseInformation?.pickUp,
        teacherName: newChildBaseInformation?.teacherName,
        teacherContact: newChildBaseInformation?.teacherContact,
        healthInsurance:
          newChildBaseInformation?.healthInsuranceName && newChildBaseInformation?.healthInsuranceNumber
            ? `${newChildBaseInformation?.healthInsuranceName}, ${newChildBaseInformation?.healthInsuranceNumber}`
            : newChildBaseInformation?.healthInsuranceName ||
              newChildBaseInformation?.healthInsuranceNumber ||
              undefined,
        liablityInsurance:
          newChildBaseInformation?.liablityInsuranceName && newChildBaseInformation?.liablityInsuranceNumber
            ? `${newChildBaseInformation?.liablityInsuranceName}, ${newChildBaseInformation?.liablityInsuranceNumber}`
            : newChildBaseInformation?.liablityInsuranceName ||
              newChildBaseInformation?.liablityInsuranceNumber ||
              undefined,
        familyDoctorName: newChildBaseInformation?.familyDoctorName,
        familyDoctorAddress: newChildBaseInformation?.familyDoctorAddress,
        familyDoctorPhone: newChildBaseInformation?.familyDoctorPhone,
        dentistName: newChildBaseInformation?.dentistName,
        dentistAddress: newChildBaseInformation?.dentistAddress,
        schoolLevel: newChildBaseInformation?.schoolLevel,
        generalHealth: newChildBaseInformation?.generalHealth,
        diseases: newChildBaseInformation?.diseases,
        hasAllergies: newChildBaseInformation?.hasAllergies,
        allergies: newChildBaseInformation?.allergies,
        needsMedicaments: newChildBaseInformation?.needsMedicaments,
        neededMedicaments: newChildBaseInformation?.neededMedicaments,
        remarks: newChildBaseInformation?.remarks,
        contactPreference: data.contactPreference,
        emergencyContactFirstName: data.emergencyContactFirstName,
        emergencyContactLastName: data.emergencyContactLastName,
        emergencyContactPhone: data.emergencyContactPhone,
        emergencyContactRemarks: data.emergencyContactRemarks,
        wayHome: newChildImportantInformation?.wayHome,
        hasCollectors: newChildImportantInformation?.hasCollectors
          ? newChildImportantInformation?.hasCollectors
          : false,
        collectorsStrategy: newChildImportantInformation?.collectorsStrategy,
        allowedToBeAloneOnSchoolYard: newChildImportantInformation?.allowedToBeAloneOnSchoolYard,
        allowedToDoHomework: newChildImportantInformation?.allowedToDoHomework,
        allowedToBeTransportedByCar: newChildImportantInformation?.allowedToBeTransportedByCar,
        allowedToReceiveMedicalHelp: newChildImportantInformation?.allowedToReceiveMedicalHelp,
        allowedToUseTickSpray: newChildImportantInformation?.allowedToUseTickSpray,
        allowedToUsePublicTransport: newChildImportantInformation?.allowedToUsePublicTransport,
        allowedToBePhotographedForInternalUse: newChildImportantInformation?.allowedToBePhotographedForInternalUse,
        allowedToBePhotographedForAds: newChildImportantInformation?.allowedToBePhotographedForAds,
        allowedToBePhotographedForOtherKids: newChildImportantInformation?.allowedToBePhotographedForOtherKids,
        allowedToContactTeachers: newChildImportantInformation?.allowedToContactTeachers,
        allowedToContactSchoolSocialWorkers: newChildImportantInformation?.allowedToContactSchoolSocialWorkers,
        busLineAndTimeforWayHome: newChildImportantInformation?.busLineAndTimeforWayHome,
        schoolOfferOverLunch: newChildImportantInformation?.schoolOfferOverLunch,
        menuSelection: newChildImportantInformation?.menuSelection,
        collectors: newChildImportantInformation?.collectors,
      },
    })

    const response = await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/new-child`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
        body,
      }
    )
    if (response.ok) {
      navigate(`modules`)
    } else {
      navigate('error')
    }
  }

  const furtherInformationPreviousHandler = () => {
    navigate('important-information')
  }

  async function moduleSubmitHandler(data: ModuleBooking): Promise<void> {
    setModuleBooking(data)
    navigate('confirmation')
  }

  function modulePreviousHandler(data: ModuleBooking): void {
    setModuleBooking(data)
    navigate('further-information')
  }

  async function confirmationSubmitHandler(data: SignatureData): Promise<void> {
    setSignatureDate(data)

    if (!moduleBooking || !family || !data || !institution || !newChildBaseInformation || !newChildImportantInformation)
      throw new Error('Cant submit uncompleted form')

    addNewContract(
      institution,
      family.id,
      convertToDomainContract(
        newChildBaseInformation,
        newChildImportantInformation,
        moduleBooking,
        institution.registrationConfig,
        newChildId
      ),
      data
    )
    navigate(`/${institution?.id}/guardian/${guardian?.id}/family`)
  }

  function confirmationPreviousHandler(): void {
    navigate('modules')
  }

  const noChild = family === undefined || family?.children?.length === 0
  const hasChildOtOfTown = family?.children?.some((child) => child.isOutOfTown) || false
  const isOutOfTown = noChild || hasChildOtOfTown

  return (
    <>
      <Outlet />
      <Routes>
        <Route
          path="base-information"
          element={
            <BaseInformationPage
              handleSubmit={baseInformationSubmitHandler}
              handlePrevious={baseInformationPreviousHandler}
              isOutOfTown={isOutOfTown}
              defaultValues={newChildBaseInformation}
            />
          }
        />
        <Route
          path="important-information"
          element={
            newChildBaseInformation ? (
              <ImportantInformationPage
                handleSubmit={importantInformationSubmitHandler}
                handlePrevious={importantInformationPreviousHandler}
                defaultValues={newChildImportantInformation}
              />
            ) : (
              <Navigate to="../base-information" replace />
            )
          }
        />
        <Route
          path="further-information"
          element={
            newChildImportantInformation ? (
              <FurtherInformationPage
                handleSubmit={furtherInformationSubmitHandler}
                handlePrevious={furtherInformationPreviousHandler}
                defaultValues={newChildFurtherInformation}
              />
            ) : (
              <Navigate to="../important-information" replace />
            )
          }
        />
        <Route
          path="modules"
          element={
            <ModuleFormPage
              schoolId={newChildBaseInformation?.schoolId}
              handleSubmit={moduleSubmitHandler}
              handlePrevious={modulePreviousHandler}
              defaultValues={moduleBooking}
              progress={1}
              taxInformation={undefined}
            />
          }
        />
        <Route
          path="confirmation"
          element={
            <ConfirmationFormPage
              guardian1={family?.guardians[0]}
              guardian2={family?.guardians[1]}
              handleSubmit={confirmationSubmitHandler}
              handlePrevious={confirmationPreviousHandler}
              defaultValues={signatureData}
              progress={9}
            />
          }
        />
        <Route path="error" element={<GuardianErrorElement />} />
        <Route path="*" element={<>404</>} />
      </Routes>
    </>
  )
}

export default NewChildRouter

const convertToDomainContract = (
  newChildBaseInformation: NewChildBaseInformation,
  newChildImportantInformation: NewChildImportantInformation,
  moduleBooking: ModuleBooking,
  registrationConfig: RegistrationConfiguration,
  childId: string
): Omit<Contract, 'createdAt' | 'state'> => {
  return {
    id: nanoid(),
    childId,
    startDate: moduleBooking.startDate!,
    endDate: registrationConfig.schoolYearEndDate ? dayjs(registrationConfig.schoolYearEndDate).toDate() : undefined,
    locationId: moduleBooking.locationId!,
    ageGroupId: moduleBooking.ageGroup!.id,
    bookedModules: moduleBooking.bookings,
    schoolId: newChildBaseInformation.schoolId,
    schoolLevel: newChildBaseInformation.schoolLevel,
    kinderGarten: newChildBaseInformation.kinderGarten,
    schoolOfferOverLunch: newChildImportantInformation.schoolOfferOverLunch,
    class: newChildBaseInformation.class,
    teacherName: newChildBaseInformation.teacherName,
    teacherContact: newChildBaseInformation.teacherContact,
  }
}
