import Decimal from 'decimal.js'
import { LoaderFunctionArgs } from 'react-router-dom'
import { z } from 'zod'
import { Institution, RegistrationConfiguration, School } from '../../types'
import { useInstitutionContext } from './institution-context'
import { DecimalSchema } from '../../helper/common-zod-schemas'

const FieldDisplayConfigSchema = z.enum(['mandatory', 'optional', 'off'])

const SchoolSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    moduleIds: z.array(z.string()),
    classes: z.array(
      z.object({
        id: z.string(),
        schoolId: z.string(),
        name: z.string(),
        teacherName: z.string(),
      })
    ),
  })
)

const RegistrationConfigurationSchema = z.object({
  id: z.string(),
  institutionId: z.string(),
  askForFamilyLanguage: FieldDisplayConfigSchema,
  askForClass: FieldDisplayConfigSchema,
  askForKinderGarten: FieldDisplayConfigSchema,
  askForSchoolName: FieldDisplayConfigSchema,
  askForTeacherName: FieldDisplayConfigSchema,
  askForTeacherContact: FieldDisplayConfigSchema,
  askForHealthInsuranceName: FieldDisplayConfigSchema,
  askForHealthInsuranceNumber: FieldDisplayConfigSchema,
  askForLiablityInsuranceName: FieldDisplayConfigSchema,
  askForLiablityInsuranceNumber: FieldDisplayConfigSchema,
  askForFamilyDoctor: FieldDisplayConfigSchema,
  askForFamilyDoctorAddress: FieldDisplayConfigSchema,
  askForDentist: FieldDisplayConfigSchema,
  askForDentistAddress: FieldDisplayConfigSchema,
  askForAllowedToBeAloneOnSchoolYard: FieldDisplayConfigSchema,
  askForAllowedToDoHomework: FieldDisplayConfigSchema,
  askForAllowedToBeTransportedByCar: FieldDisplayConfigSchema,
  askForAllowedToReceiveMedicalHelp: FieldDisplayConfigSchema,
  askForAllowedToUseTickSpray: FieldDisplayConfigSchema,
  askForAllowedToUsePublicTransport: FieldDisplayConfigSchema,
  askForAllowedToBePhotographedForInternalUse: FieldDisplayConfigSchema,
  askForAllowedToBePhotographedForAds: FieldDisplayConfigSchema,
  askForAllowedToBePhotographedForOtherKids: FieldDisplayConfigSchema,
  askForAllowedToContactTeachers: FieldDisplayConfigSchema,
  askForAllowedToContactSchoolSocialWorkers: FieldDisplayConfigSchema,
  askForBusAndTimeOnWayHome: FieldDisplayConfigSchema,
  askForWayHome: z.boolean(),
  askForSchoolOfferOverLunch: FieldDisplayConfigSchema,
  askForPickup: FieldDisplayConfigSchema,
  askForMenuSelection: FieldDisplayConfigSchema,
  hasMenuWithoutPork: z.boolean(),
  hasMenuWithPorridge: z.boolean(),
  askForLocationBeforeAndAfterModule: FieldDisplayConfigSchema,
  selectableTimeIntervalForLocationBeforeAndAfterModule: z.number(),
  askIfChildHasSiblings: FieldDisplayConfigSchema,
  askForFurtherSchoolAgeChildren: FieldDisplayConfigSchema,
  askIfChildVisitsOtherInstitution: FieldDisplayConfigSchema,
  askIfChildVisitsPhisioTherapy: FieldDisplayConfigSchema,
  askIfChildIsVaccinated: FieldDisplayConfigSchema,
  askIfChildIsTakingNap: FieldDisplayConfigSchema,
  allowOutOfTownChildrenForHolidayCare: z.boolean(),
  hasSchoolLevelBaby: z.boolean(),
  hasSchoolLevelToddler: z.boolean(),
  hasSchoolLevelKindergarten: z.boolean(),
  hasSchoolLevelSchool: z.boolean(),
  possibleStartDates: z.array(z.string()),
  welcomeText: z.string(),
  beforeSubmitText: z.string(),
  finishText: z.string(),
  enableSingleSignature: z.boolean(),
  leafletName: z.string(),
  askForGuardianDateOfBirth: FieldDisplayConfigSchema,
  askForGuardianEmployer: FieldDisplayConfigSchema,
  askForGuardianOccupation: FieldDisplayConfigSchema,
  askForGuardianWorkPhoneNumber: FieldDisplayConfigSchema,
  askForGuardianMaritalStatus: FieldDisplayConfigSchema,
  askForChildReligion: FieldDisplayConfigSchema,
  askForChildLivesWith: FieldDisplayConfigSchema,
  askForChildNationality: FieldDisplayConfigSchema,
  askForChildGeneralHealth: FieldDisplayConfigSchema,
  schoolYearStartDate: z.string(),
  schoolYearEndDate: z.string(),
  infantsStartAgeInMonths: z.number(),
  toddlerStartAgeInMonths: z.number(),
  askForAdjustmentPeriodStartDate: FieldDisplayConfigSchema,
})

const AgeGroup = z.object({
  id: z.string(),
  name: z.string(),
  careFactorFaBe: z.preprocess((priceAsString) => {
    return new Decimal(priceAsString ? (priceAsString as string) : '0')
  }, z.instanceof(Decimal)),
  careFactorHF: z.preprocess((priceAsString) => {
    return new Decimal(priceAsString ? (priceAsString as string) : '0')
  }, z.instanceof(Decimal)),
  careFactorAssistant: z.preprocess((priceAsString) => {
    return new Decimal(priceAsString ? (priceAsString as string) : '0')
  }, z.instanceof(Decimal)),
  careFactorYouthful: z.preprocess((priceAsString) => {
    return new Decimal(priceAsString ? (priceAsString as string) : '0')
  }, z.instanceof(Decimal)),
  bookableFromGuardians: z.boolean(),
})

const ModuleAgeGroupSchema = z.object({
  moduleId: z.string(),
  ageGroupId: z.string(),
  priceInclVat: DecimalSchema,
  priceMonthlyInclVat: DecimalSchema,
  priceSiblingDiscount: DecimalSchema,
  priceSiblingDiscountMonthly: DecimalSchema,
})
const ModuleSchema = z.object({
  id: z.string(),
  name: z.string(),
  locationId: z.string(),
  description: z.string(),
  weekday: z.enum(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']),
  startTime: z.string(),
  endTime: z.string(),
  ageGroups: z.array(ModuleAgeGroupSchema),
  priceInclVat: DecimalSchema,
  priceMonthlyInclVat: DecimalSchema,
  priceSiblingDiscount: DecimalSchema,
  priceSiblingDiscountMonthly: DecimalSchema,
  feesAdditionalBooking: DecimalSchema,
  priceHourlyInclVat: DecimalSchema,
  chargeCanceledBookings: z.boolean(),
})

const FeaturesSchema = z.object({
  subsidiesHuenenberg: z.boolean(),
  abacusInvoiceExport: z.boolean(),
  innosolvInvoiceExport: z.boolean(),
  dialogInvoiceExport: z.boolean(),
  littleButterfliesPricing: z.boolean().default(false),
  ennetbadenPricing: z.boolean().default(false),
  schereSteiPapierPricing: z.boolean(),
  siblingsDiscount10YoungerChild: z.boolean(),
  hidePricesInRegistration: z.boolean(),
  buchrainPricing: z.boolean(),
  adoptGroupFromPreviousYear: z.boolean(),
  surbtalPricing: z.boolean(),
})
const HolidayCareSchema = z.object({
  id: z.string(),
  locationId: z.string().optional(),
  date: z.preprocess((dateAsString) => {
    return new Date(dateAsString as string)
  }, z.date()),
  startTime: z.string(),
  endTime: z.string(),
  title: z.string(),
  description: z.string(),
  cost: z.preprocess((priceAsString) => {
    return new Decimal(priceAsString ? (priceAsString as string) : '0')
  }, z.instanceof(Decimal)),
  minOccupancy: z.number(),
  maxOccupancy: z.number(),
  bookingCount: z.number(),
  registrationDeadline: z.string(),
  executionDecisionDate: z.string().optional(),
  executionDecision: z.enum(['open', 'takesPlace', 'cancelled']),
  holidayCareBundleId: z.string().nullable(),
})
const LocationSchema = z.object({
  id: z.string(),
  name: z.string(),
  ageGroups: z.array(AgeGroup),
  childCareSlots: z.number(),
  enableFlexBooking: z.boolean(),
  earliestSignUpDate: z
    .preprocess((dateAsString) => {
      return new Date(dateAsString as string)
    }, z.date())
    .optional(),
  modules: z.array(ModuleSchema),
  closingDays: z.array(z.string()),
})

const InstitutionSchema = z.object({
  id: z.string(),
  name: z.string(),
  locations: z.array(LocationSchema),
  features: FeaturesSchema,
  moduleCancellationPeriodInHours: z.number(),
  contractNoticePeriodInDays: z.number(),
  moduleCancellationFeeInCHF: z.number(),
  bankingAccount: z.string(),
  email: z.string().optional(),
  signingFirstName: z.string(),
  signingLastName: z.string(),
  signingFunction: z.string(),
  paymentTargetDays: z.number(),
  street: z.string(),
  zip: z.string(),
  city: z.string(),
  invoicingByLocation: z.boolean(),
  registrationConfig: RegistrationConfigurationSchema,
  schools: SchoolSchema,
  firstInvoiceNumber: z.number(),
  holidayCare: z.array(HolidayCareSchema),
  siblingDiscountType: z.enum(['onAnySibling', 'onSchoolAgeSiblings', 'onOlderSibling', 'none']),
  kibonInstitutionId: z.string(),
  registrationFeeInCHF: DecimalSchema,
  restrictModulesByContractAgeGroup: z.boolean(),
  planningType: z.enum(['byModule', 'byTime']),
  planningVisibleUntil: z.string(),
})

const loadInstitution = async (
  args: LoaderFunctionArgs
): Promise<Institution & { registrationConfig: RegistrationConfiguration; schools: School[] }> => {
  const {
    params: { institutionId },
  } = args

  if (institutionId) {
    const institution = await fetchInstitution(institutionId)
    useInstitutionContext.getState().setInstitution(institution)
    return institution
  } else {
    throw Error('Need institutionId as param in order to load institution.')
  }
}

export const fetchInstitution = async (institutionId: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/configuration/institutions/${institutionId}`
  )
  const deserializedInstitution = await response.json()
  const institution = InstitutionSchema.parse(deserializedInstitution)
  return institution
}

export default loadInstitution
