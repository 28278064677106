import { t } from '@lingui/macro'
import { Alert, Button, Input, notification, Space, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { signOut } from 'supertokens-web-js/recipe/emailpassword'
import FormPage from '../../../components/form/form-page'
import { useGuardianContext } from './guardian-context'
import { FocusEventHandler, useEffect, useState } from 'react'
import AppStores from '../../../components/app-stores'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { LocalNotifications } from '@capacitor/local-notifications'

const { Title } = Typography

const GuardianSettingsPage = () => {
  const { guardian, user } = useGuardianContext()
  const navigate = useNavigate()
  const [deleteAccountRequested, setDeleteAccountRequested] = useState(false)
  const [appVersion, setAppVersion] = useState('')

  if (Capacitor.isNativePlatform()) {
    App.getInfo().then((info) => setAppVersion(info.version))
  }

  const handleLogout = async () => {
    await signOut()
    useGuardianContext.setState({ user: undefined, guardians: undefined })
    navigate('/auth')
  }

  const handleDeleteAccountRequest = () => {
    setDeleteAccountRequested(true)
  }

  const handleCancelDelete = () => {
    setDeleteAccountRequested(false)
  }

  const handleDeleteAccount = async () => {
    await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${guardian?.institutionId}/guardian/delete-account`,
      {
        method: 'PUT',
      }
    )
    handleLogout()
  }

  const currentMorphEmail = localStorage.getItem('morphEmail')
  console.log('currentMorphEmail', currentMorphEmail)
  const handleMorphEmailBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const morphEmail = event.target.value
    if (morphEmail) {
      localStorage.setItem('morphEmail', morphEmail)
    } else {
      localStorage.removeItem('morphEmail')
    }
  }

  return (
    <FormPage title={t({ message: 'Konto-Einstellungen' })}>
      <>
        <section style={{ paddingBlockEnd: '1em' }}>
          {t({ message: `Sie sind angemeldet als ${guardian?.address.firstName} ${guardian?.address.lastName}` })}.
          <br />({guardian?.address?.email})
        </section>
        <section style={{ paddingBlockEnd: '1em' }}>
          <Button onClick={handleLogout}>{t({ message: 'Logout' })}</Button>
        </section>
        <section style={{ paddingBlockEnd: '1em' }}>
          {deleteAccountRequested ? (
            <Alert
              type="warning"
              message={t({ message: 'Wollen Sie Ihr Konto wirklich löschen?' })}
              action={
                <Space direction="vertical">
                  <Button onClick={handleDeleteAccount} danger type="primary">
                    {t({ message: 'Ja, Konto löschen' })}
                  </Button>
                  <Button onClick={handleCancelDelete}>{t({ message: 'Konto behalten' })}</Button>
                </Space>
              }
            ></Alert>
          ) : (
            <Button onClick={handleDeleteAccountRequest}>{t({ message: 'Konto löschen' })}</Button>
          )}
        </section>
        {Capacitor.isNativePlatform() && <PushNotificationsSection />}
        {Capacitor.isNativePlatform() && (
          <section>
            {t({ message: 'App-Version' })}: {appVersion}
          </section>
        )}

        <section>
          <AppStores />
        </section>
        {user && user.isLeobaAdmin && (
          <section>
            <Title level={2}>Super-Admin-Einstellungen</Title>
            <label>Beim nächsten Login sich mit folgender E-Mail ausgeben:</label>
            <Input type="email" onBlur={handleMorphEmailBlur} defaultValue={currentMorphEmail || undefined} />
          </section>
        )}
      </>
    </FormPage>
  )
}

const PushNotificationsSection = () => {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null)
  useEffect(() => {
    const checkPermissions = async () => {
      const pushPermission = await PushNotifications.checkPermissions()
      const localPermission = await LocalNotifications.checkPermissions()
      setHasPermission(pushPermission.receive === 'granted' && localPermission.display === 'granted')
    }
    checkPermissions()
  }, [])

  const { guardian } = useGuardianContext()

  const sendTestNotification = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${guardian?.institutionId}/guardian/send-test-notification?guardianId=${guardian?.id}`,
        {
          method: 'POST',
        }
      )
      notification.success({
        message: t`Test-Benachrichtigung wurde gesendet. Es kann bis zu einer halben Minute dauern, bis die Nachricht eintrifft.`,
        closable: true,
        duration: 30,
      })
    } catch {
      notification.error({ message: t`Fehler beim Senden der Test-Benachrichtigung` })
    }
  }
  return (
    <section style={{ paddingBottom: '2em' }}>
      <h3>{t`Push-Benachrichtigungen`}</h3>
      {hasPermission === true && (
        <>
          <p style={{ color: 'green' }}>{t`Die App hat die Berechtigung, um Push-Benachrichtigungen zu empfangen.`}</p>
          {Capacitor.getPlatform() === 'android' && (
            <p
              style={{ color: 'green' }}
            >{t`Sollten Sie trotzdem Probleme haben, Notifikationen zu empfangen, prüfen Sie die Berechtigung einzelner Benachrichtigungskategorien oder herstellerspezifische Einstellungen.`}</p>
          )}
        </>
      )}
      {hasPermission === false && (
        <>
          <p
            style={{ color: 'orange' }}
          >{t`Die App hat keine Berechtigung, um Push-Benachrichtigungen zu empfangen. Prüfen Sie die Berechtigungen. Es könnte auch helfen die App zu deinstallieren und dann neu zu installieren.`}</p>
        </>
      )}
      <Button onClick={sendTestNotification}>{t`Test-Benachrichtigung senden`}</Button>
    </section>
  )
}

export default GuardianSettingsPage
