import { t } from '@lingui/macro'
import { Card, Modal } from 'antd'
import { useState } from 'react'
import translateCollectorsStrategy from '../../../helper/translate-collectors-strategy'
import translatedWayHome from '../../../helper/translated-way-home'
import { useInstitutionContext } from '../../../routes/institution/institution-context'
import { Field } from '../../form/field'
import TextField from '../../text-field'
import YesNoTranslatedField from '../../yes-no-translated-field'
import ChildImportantInformationForm, {
  NewChildImportantInformation,
} from '../child-form/child-important-information-form'
import ChildCardSection from './child-card-section'
import translateMenuSelection from '../../../helper/translate-menu-selection'

type Props = {
  childId: string
  newChildImportantInformation: NewChildImportantInformation
  onSubmitEditedImportantInformation: (information: NewChildImportantInformation, childId: string) => void
}

const ChildCardImportantInformationSection = ({
  onSubmitEditedImportantInformation,
  newChildImportantInformation,
  childId,
}: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const { institution } = useInstitutionContext()

  const showEditModal = () => {
    setIsEditModalOpen(true)
  }

  const handleEditModalOk = (data: NewChildImportantInformation) => {
    onSubmitEditedImportantInformation(data, childId)
    setIsEditModalOpen(false)
  }

  const handleEditModalCancel = () => {
    setIsEditModalOpen(false)
  }

  const handleOnEdit = () => {
    showEditModal()
  }

  return (
    <ChildCardSection onEdit={handleOnEdit} title={t({ message: 'Wichtige Informationen' })}>
      <>
        {institution?.registrationConfig.askForWayHome && (
          <>
            <TextField
              label={t({ message: 'Heimweg' })}
              value={translatedWayHome(newChildImportantInformation.wayHome)}
            />
            {(newChildImportantInformation.wayHome === 'fetched' ||
              newChildImportantInformation.wayHome === 'partiallyFetched') && (
              <>
                <TextField
                  label={t({ message: 'Mein Kind darf von folgenden Personen abgeholt werden' })}
                  value={translateCollectorsStrategy(newChildImportantInformation.collectorsStrategy)}
                />
                {newChildImportantInformation.collectorsStrategy === 'exclusive' && (
                  <Field label={t({ message: 'Abholungsberechtigte Personen:' })}>
                    <>
                      {newChildImportantInformation.collectors.map((collector) => {
                        return (
                          <Card size="small" style={{ marginBottom: 8 }}>
                            <TextField label={t({ message: 'Vorname' })} value={collector.firstName} />
                            <TextField label={t({ message: 'Nachname' })} value={collector.lastName} />
                            <TextField label={t({ message: 'Beziehung zu Kind' })} value={collector.comment} />
                          </Card>
                        )
                      })}
                    </>
                  </Field>
                )}
              </>
            )}
          </>
        )}

        {newChildImportantInformation.busLineAndTimeforWayHome && (
          <TextField
            label={t({
              message:
                'Nutzt Ihr Kind den ÖV auf dem Heimweg? Dann geben Sie uns bitte die genaue Linie, Busfarbe und Uhrzeit an.',
            })}
            value={newChildImportantInformation.busLineAndTimeforWayHome}
          />
        )}

        {newChildImportantInformation.schoolOfferOverLunch && (
          <TextField
            label={t({
              message:
                'Welches Angebot (Musikunterricht, Sport, Nachhilfe etc.) wird während der Betreuungszeit über den Mittag besucht? Bitte geben Sie auch die Uhrzeit an.',
            })}
            value={newChildImportantInformation.schoolOfferOverLunch}
          />
        )}

        {newChildImportantInformation.menuSelection && (
          <TextField
            label={t({
              message: 'Menüwahl',
            })}
            value={translateMenuSelection(newChildImportantInformation.menuSelection)}
          />
        )}

        {(institution?.registrationConfig.askForAllowedToBeAloneOnSchoolYard === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToBeAloneOnSchoolYard === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToBeAloneOnSchoolYard}
            label={
              `${
                institution.id === 'cCJtg4Off1HhGbqe4bh0i' // Spezialfeature für Eiken
                  ? 'Ab 4. Klasse: '
                  : ''
              }` +
              t({
                message:
                  'Mein Kind darf sich nach dem Essen und während der Betreuungszeit alleine auf dem Pausenplatz aufhalten',
              })
            }
          />
        )}
        {(institution?.registrationConfig.askForAllowedToDoHomework === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToDoHomework === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToDoHomework}
            label={t({
              message: 'Mein Kind soll so weit als möglich die Hausaufgaben während der Betreuungszeit erledigen',
            })}
          />
        )}
        {(institution?.registrationConfig.askForAllowedToBeTransportedByCar === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToBeTransportedByCar === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToBeTransportedByCar}
            label={t({
              message:
                'Mein Kind darf für Transporte, in Notfällen oder für kürzere Ausflüge im Privatauto mit vorhandenem Kindersitz transportiert werden?',
            })}
          />
        )}
        {(institution?.registrationConfig.askForAllowedToReceiveMedicalHelp === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToReceiveMedicalHelp === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToReceiveMedicalHelp}
            label={t({
              message:
                'Mein Kind darf, sofern notwendig, mit Desinfektionsmitteln, Wundsalben o.Ä. erstversorgt werden.',
            })}
          />
        )}

        {(institution?.registrationConfig.askForAllowedToUseTickSpray === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToUseTickSpray === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToUseTickSpray}
            label={t({
              message:
                'Mein Kind muss mit Zeckenspray eingesprüht werden, falls nicht, darf es nur mit entsprechender Kleidung in den Tagi-Wald (Kleidung ist in der Tagi).',
            })}
          />
        )}
        {(institution?.registrationConfig.askForAllowedToUsePublicTransport === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToUsePublicTransport === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToUsePublicTransport}
            label={t({
              message: 'Mein Kind darf in Begleitung von Fachpersonal die öffentlichen Verkehrsmittel benutzen?',
            })}
          />
        )}
        {(institution?.registrationConfig.askForAllowedToBePhotographedForInternalUse === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToBePhotographedForInternalUse === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToBePhotographedForInternalUse}
            label={t({
              message:
                'Ich bin einverstanden, dass Fotomaterial mit meinem abgebildeten Kind für interne Zwecke (Fotowände, etc.) verwendet werden darf?',
            })}
          />
        )}
        {(institution?.registrationConfig.askForAllowedToBePhotographedForAds === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToBePhotographedForAds === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToBePhotographedForAds}
            label={t({
              message:
                'Ich bin einverstanden, dass Fotomaterial mit meinem abgebildeten Kind für externe Zwecke (Homepage, Schularbeiten, etc.) verwendet werden darf?',
            })}
          />
        )}
        {(institution?.registrationConfig.askForAllowedToBePhotographedForOtherKids === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToBePhotographedForOtherKids === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToBePhotographedForOtherKids}
            label={t({
              message:
                'Ich bin einverstanden, dass Fotomaterial mit meinem abgebildeten Kind für die Portfolios anderer Kinder verwendet werden darf?',
            })}
          />
        )}
        {(institution?.registrationConfig.askForAllowedToContactTeachers === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToContactTeachers === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToContactTeachers}
            label={t({ message: 'Ich erlaube den Austausch zwischen Leitung und Kindergarten bzw. Schulpersonal' })}
          />
        )}
        {(institution?.registrationConfig.askForAllowedToContactSchoolSocialWorkers === 'mandatory' ||
          institution?.registrationConfig.askForAllowedToContactSchoolSocialWorkers === 'optional') && (
          <YesNoTranslatedField
            value={newChildImportantInformation.allowedToContactSchoolSocialWorkers}
            label={t({ message: 'Ich erlaube den Austausch zwischen Leitung und Schulsozialarbeit' })}
          />
        )}

        {(institution?.registrationConfig.askIfChildVisitsOtherInstitution === 'mandatory' ||
          institution?.registrationConfig.askIfChildVisitsOtherInstitution === 'optional') && (
          <TextField
            label={t({
              message: `Besucht Ihr Kind während der Woche noch andere Angebote oder wird zusätzlich zur ${institution.name} fremdbetreut?`,
            })}
            value={newChildImportantInformation.visitsOtherInstitution}
          />
        )}
        {(institution?.registrationConfig.askIfChildVisitsPhisioTherapy === 'mandatory' ||
          institution?.registrationConfig.askIfChildVisitsPhisioTherapy === 'optional') && (
          <TextField
            label={t({
              message: `Besucht Ihr Kind die Logopädie, Ergotherapie, etc.?`,
            })}
            value={newChildImportantInformation.visitsPhisioTherapy}
          />
        )}
        {(institution?.registrationConfig.askIfChildIsVaccinated === 'mandatory' ||
          institution?.registrationConfig.askIfChildIsVaccinated === 'optional') && (
          <YesNoTranslatedField
            label={t({
              message: `Ist ihr Kind geimpft?`,
            })}
            value={newChildImportantInformation.isVaccinated}
          />
        )}
        {(institution?.registrationConfig.askIfChildIsTakingNap === 'mandatory' ||
          institution?.registrationConfig.askIfChildIsTakingNap === 'optional') && (
          <YesNoTranslatedField
            label={t({
              message: `Ist ihr Kind an Mittagsschlaf oder –ruhe gewohnt?`,
            })}
            value={newChildImportantInformation.isTakingNap}
          />
        )}
        <Modal
          title={t({ message: 'Wichtige Informationen bearbeiten' })}
          open={isEditModalOpen}
          onCancel={handleEditModalCancel}
          footer={null}
        >
          <ChildImportantInformationForm
            defaultValues={newChildImportantInformation}
            handleSubmit={handleEditModalOk}
            submitButtonText={t({ message: 'Speichern' })}
          />
        </Modal>
      </>
    </ChildCardSection>
  )
}

export default ChildCardImportantInformationSection
