import { z } from 'zod'
import { LIVES_WITH_VALUES } from '../../../domain/child/lives-with'
import { RELIGIONS } from '../../../domain/child/religion'
import {
  Child,
  CollectorsStrategy,
  ContractState,
  Family,
  GeneralHealth,
  Guardian,
  KindergartenLevel,
  MaritalStatus,
  MenuSelection,
  SchoolLevel,
  Sex,
  WayHome,
} from '../../../types'

export const loadFamily = async (guardian: Guardian & { institutionId: string }): Promise<Family> => {
  const response = await fetch(
    `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${guardian.institutionId}/guardian/family-data?familyId=${guardian?.familyId}`,
    {
      headers: { accept: 'application/json' },
    }
  )

  if (response.ok) {
    return FamilyDataSchema.parse(await response.json())
  } else {
    throw Error(`Could not load family data (status ${response.status})`)
  }
}

const AddressSchema = z.object({
  organisation: z.string(),
  firstName: z.string(),
  familyId: z.string(),
  lastName: z.string(),
  street: z.string(),
  zip: z.string(),
  city: z.string(),
  id: z.string(),
  email: z.string(),
  accountingDebitNumber: z.string().optional(),
})
const DateSchema = z.preprocess((dateAsString) => {
  return new Date(dateAsString as string)
}, z.date())

const SexSchema = z.string().transform((original) => original as Sex)

const GuardianSchema = z.object({
  id: z.string(),
  familyId: z.string(),
  sex: SexSchema.optional(),
  dateOfBirth: DateSchema.optional(),
  phone: z.string(),
  phoneWork: z.string(),
  maritalStatus: z
    .string()
    .transform((original) => original as MaritalStatus)
    .optional(),
  soleCustody: z.boolean(),
  occupation: z.string().optional(),
  employer: z.string().optional(),
  address: AddressSchema,
})

const ChildSchema = z
  .object({
    id: z.string(),
    familyId: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    sex: SexSchema.optional(),
    dateOfBirth: DateSchema,
    familyLanguage: z.string().optional(),
    isOutOfTown: z.boolean(),
    pickUp: z.boolean().optional(),
    healthInsurance: z.string().optional(),
    liablityInsurance: z.string().optional(),
    familyDoctorName: z.string(),
    familyDoctorAddress: z.string().optional(),
    familyDoctorPhone: z.string(),
    dentistName: z.string().optional(),
    dentistAddress: z.string().optional(),
    generalHealth: z.string().transform((original) => original as GeneralHealth),
    diseases: z.string(),
    hasAllergies: z.boolean(),
    allergies: z.string().optional(),
    needsMedicaments: z.boolean(),
    neededMedicaments: z.string().optional(),
    remarks: z.string().optional(),
    contactPreference: z.string().optional(),
    emergencyContactFirstName: z.string(),
    emergencyContactLastName: z.string(),
    emergencyContactPhone: z.string(),
    emergencyContactRemarks: z.string().optional(),
    wayHome: z.string().transform((original) => original as WayHome),
    busLineAndTimeforWayHome: z.string().optional(),
    menuSelection: z
      .string()
      .transform((original) => original as MenuSelection)
      .optional(),
    hasCollectors: z.boolean(),
    collectorsStrategy: z.string().transform((original) => original as CollectorsStrategy),
    allowedToBeAloneOnSchoolYard: z.boolean().optional(),
    allowedToDoHomework: z.boolean().optional(),
    allowedToBeTransportedByCar: z.boolean().optional(),
    allowedToReceiveMedicalHelp: z.boolean().optional(),
    allowedToUseTickSpray: z.boolean().optional(),
    allowedToUsePublicTransport: z.boolean().optional(),
    allowedToBePhotographedForInternalUse: z.boolean().optional(),
    allowedToBePhotographedForAds: z.boolean().optional(),
    allowedToBePhotographedForOtherKids: z.boolean().optional(),
    allowedToContactTeachers: z.boolean().optional(),
    allowedToContactSchoolSocialWorkers: z.boolean().optional(),
    visitsOtherInstitution: z.string().optional(),
    visitsPhisioTherapy: z.string().optional(),
    isVaccinated: z.boolean().optional(),
    isTakingNap: z.boolean().optional(),
    livesWith: z.enum(LIVES_WITH_VALUES).nullable(),
    religion: z.enum(RELIGIONS).nullable(),
    nationality: z.string().optional(),
    collectors: z.array(
      z.object({
        id: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        comment: z.string(),
      })
    ),
    contracts: z.array(
      z.object({
        id: z.string(),
        locationId: z.string(),
        startDate: DateSchema,
        endDate: DateSchema.optional(),
        ageGroupId: z.string(),
        createdAt: DateSchema,
        bookedModules: z.array(z.object({ moduleId: z.string(), ageGroupId: z.string().optional() })),
        state: z.string().transform((original) => original as ContractState),
        childId: z.string(),
        class: z.string().optional(),
        kinderGarten: z
          .string()
          .transform((original) => original as KindergartenLevel)
          .optional(),
        teacherName: z.string().optional(),
        teacherContact: z.string().optional(),
        schoolLevel: z.string().transform((original) => original as SchoolLevel),
        schoolId: z.string().optional(),
        schoolOfferOverLunch: z.string().optional(),
      })
    ),
  })
  .transform(
    (child): Child => ({
      ...child,
      sex: child.sex,
      familyLanguage: child.familyLanguage,
      pickUp: child.pickUp,
      healthInsurance: child.healthInsurance,
      liablityInsurance: child.liablityInsurance,
      familyDoctorAddress: child.familyDoctorAddress,
      dentistName: child.dentistName,
      dentistAddress: child.dentistAddress,
      allergies: child.allergies,
      neededMedicaments: child.neededMedicaments,
      remarks: child.remarks,
      contactPreference: child.contactPreference,
      emergencyContactRemarks: child.emergencyContactRemarks,
      busLineAndTimeforWayHome: child.busLineAndTimeforWayHome,
      menuSelection: child.menuSelection,
      allowedToBeAloneOnSchoolYard: child.allowedToBeAloneOnSchoolYard,
      allowedToDoHomework: child.allowedToDoHomework,
      allowedToBeTransportedByCar: child.allowedToBeTransportedByCar,
      allowedToReceiveMedicalHelp: child.allowedToReceiveMedicalHelp,
      allowedToUseTickSpray: child.allowedToUseTickSpray,
      allowedToUsePublicTransport: child.allowedToUsePublicTransport,
      allowedToBePhotographedForInternalUse: child.allowedToBePhotographedForInternalUse,
      allowedToBePhotographedForAds: child.allowedToBePhotographedForAds,
      allowedToBePhotographedForOtherKids: child.allowedToBePhotographedForOtherKids,
      allowedToContactTeachers: child.allowedToContactTeachers,
      allowedToContactSchoolSocialWorkers: child.allowedToContactSchoolSocialWorkers,
      visitsOtherInstitution: child.visitsOtherInstitution,
      visitsPhisioTherapy: child.visitsPhisioTherapy,
      isVaccinated: child.isVaccinated,
      isTakingNap: child.isTakingNap,
      nationality: child.nationality,
    })
  )

const TagSchema = z.object({
  tagId: z.string(),
  data: z.string().or(z.number()).nullable(),
})

const FamilyDataSchema = z.object({
  id: z.string(),
  guardians: z.array(GuardianSchema),
  children: z.array(ChildSchema),
  billingAddress: AddressSchema,
  tags: z.array(TagSchema),
  lastChecked: z.string(),
})
